import React from "react";
import "./../styles/global.css";
import IndexPage from "./index";
import Checkout from "./checkout";
import Terms from "./terms";
import ContactUs from "./contact";
import About from "./about";
import PrivacyPolicy from "./privacypolicy";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import "./../util/analytics";
import Chat from "./../components/Chat";

function App(props) {
  return (
    <>
      <Chat />
      <Router>
        <Switch>
          <Route exact path="/" component={IndexPage} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/contact" component={ContactUs} />
          <Route exact path="/about" component={About} />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
