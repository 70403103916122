import React from "react";
import Meta from "./../components/Meta";


function ContactUs(props) {

  return (
    <>
      <Meta title="About - Malina Labs" />
      <div className="space-y-10 container py-20">
        <div className="text-left leading-loose">
        <h2 className="text-3xl md:text-4xl font-extrabold">About Malina Labs</h2>
        <p className="mt-4 text-lg md:text-xl text-left">
            Malina Labs LLC delivers exceptional software to improve people's health and productivity.  <br /><br />
            Questions? Support? Returns?<br />
            No problem, just contact us at <a href="mailto:support@malinalabs.com">support@malinalabs.com</a> <br /><br />
        </p>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
