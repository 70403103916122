import React from "react";
import Meta from "./../components/Meta";


function ContactUs(props) {

  return (
    <>
      <Meta title="Contact us" />
      <div className="space-y-10 container py-20">
        <div className="text-left leading-loose">
        <h2 className="text-3xl md:text-4xl font-extrabold">Contact us</h2>
        <p className="mt-4 text-lg md:text-xl text-left">
            Questions? Support? Returns?<br />
            No problem, just contact us at <a href="mailto:support@malinalabs.com">support@malinalabs.com</a> <br /><br />
            <b>Mailing address:</b><br />
            Malina Labs LLC<br />
            1401 21st Street STE R <br />
            Sacramento, CA 95811<br />
            (650) 670-8674
        </p>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
