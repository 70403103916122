import React, { useEffect } from "react";
import Meta from "./../components/Meta";

function Terms(props) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);  

  return (
    <>
      <Meta title="Terms of service" />
      <div name="termly-embed" data-id="ead61bf7-287b-430c-8d2e-0f6b202a0f8d" data-type="iframe"></div>
    </>
  );
}

export default Terms;
