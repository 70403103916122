import React, { useEffect } from "react";
import Meta from "./../components/Meta";


function PrivacyPolicy(props) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);  

  return (
    <>
      <Meta title="Terms of service" />
      <div name="termly-embed" data-id="a65b3ec9-7c8f-4500-8d3c-97fd5792086d" data-type="iframe"></div>
    </>
  );
}

export default PrivacyPolicy;
